<template>
    <div>
        <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText" >购买订单</span>
    </div> 
<div class="tableArea" >
    <el-table
    class="tables"
    :data="tableData1"
    border
    style="width: 100%">
    <el-table-column
      label="订单号"
      align="center"
      prop="orderNumber"
      >
    </el-table-column>
    <el-table-column
      prop="orderstatus"
      align="center"
      label="订单状态"
      >
      <template slot-scope="{row}"> 
        {{ orderStatus[row.orderstatus] }}
      </template>
    </el-table-column>
    <el-table-column
    align="center"
    prop="orderAmount"
      label="订单金额(元)">
    </el-table-column>
    <el-table-column
    align="center"
    prop="productNum"
      label="商品数量">
    </el-table-column>
    <el-table-column
    align="center"
      label="是否退款">
    </el-table-column>
    <el-table-column
    align="center"
      label="退款金额(元)">
    </el-table-column>
    <el-table-column
    align="center"
    prop="consigneeAddress"
      label="收获地址">
    </el-table-column>
    <el-table-column
    align="center"
    prop="consigneePhone"
      label="联系方式">
    </el-table-column>
    <el-table-column
    align="center"
    prop="merchantName"
      label="所需店铺">
    </el-table-column>
    <el-table-column
    align="center"
    prop="paidDate"
      label="下单时间">
    </el-table-column>
    <el-table-column
    align="center"
      label="操作">
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      :current-page="queryInfo1.currPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo1.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total1">
    </el-pagination>
</div>
<div class="ziliao" style="margin-top:12px">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText" >售卖订单</span>
    </div> 
<div class="tableArea" >
    <el-table
    class="tables"
    :data="tableData2"
    border
    style="width: 100%">
    <el-table-column
      label="订单号"
      align="center"
      prop="orderNumber"
     >
    </el-table-column>
    <el-table-column
      prop="orderstatus"
      align="center"
      label="订单状态"
      >
      <template slot-scope="{row}"> 
        {{ orderStatus[row.orderstatus] }}
      </template>
    </el-table-column>
    <el-table-column
    align="center"
    prop="orderAmount"
      label="订单金额(元)">
    </el-table-column>
    <el-table-column
    align="center"
    prop="productNum"
      label="商品数量">
    </el-table-column>
    <el-table-column
    align="center"
      label="是否退款">
    </el-table-column>
    <el-table-column
    align="center"
      label="退款金额(元)">
    </el-table-column>
    <el-table-column
    align="center"
    prop="consigneeAddress"
      label="收获地址">
    </el-table-column>
    <el-table-column
    align="center"
    prop="consigneePhone"
      label="联系方式">
    </el-table-column>
    <el-table-column
    align="center"
    prop="merchantName"
      label="所需店铺">
    </el-table-column>
    <el-table-column
    align="center"
    prop="paidDate"
      label="下单时间">
    </el-table-column>
    <el-table-column
    align="center"
      label="操作">
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange2"
      @current-change="handleCurrentChange2"
      :current-page="queryInfo2.currPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo2.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total2">
    </el-pagination>
</div>
    </div>
</template>

<script>
    export default {
      props:{
            current:{
                type:String,
                default:"BaseInfoList"
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        data(){
          return{
            orderStatus:['待支付','待发货','已发货','已完成','退款中','已退款'],
                total1:0,
                tableData1:[],
                queryInfo1:{
                    currPage:1,
                    pageSize:10,
                    condition:{
                        memberCode:'',
                        payStatus:'1'
                    }
                },
                total2:0,
                tableData2:[],
                queryInfo2:{
                    currPage:1,
                    pageSize:10,
                    condition:{
                        memberCode:'',
                        payStatus:"1"
                    }
                }
            }
        },
        created(){
            this.queryInfo1.condition.memberCode=this.memberCode
            this.queryInfo2.condition.memberCode=this.memberCode
            this.getTable1()
            this.getTable2()
            
        },
        methods:{
          async getTable1(){
                let res=await this.$http.post('order/listByMember',this.queryInfo1)
                if(res.data.code==200){
                    this.total1=res.data.data.count
                    this.tableData1=res.data.data.data
                }
            },
            async getTable2(){
                let res=await this.$http.post('order/listByMemberSale',this.queryInfo2)
                if(res.data.code==200){
                    this.total2=res.data.data.count
                    this.tableData2=res.data.data.data
                }
            },
            handleSizeChange1(v){
                this.queryInfo1.pageSize=v
                this.getTable1()
            },
            handleCurrentChange1(v){
                this.queryInfo1.currPage=v
                this.getTable1()
            },
        
            handleSizeChange2(v){
                this.queryInfo2.pageSize=v
                this.getTable2()
            },
            handleCurrentChange2(v){
                this.queryInfo2.currPage=v
                this.getTable2()
            }
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
.tableArea{
    padding-right: 30px;
    box-sizing: border-box;
}
.tables{
    th{
        padding: 5px 0;
    }
}
</style>