<template>
    <div>
        <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText" >关注</span>
    </div> 
<div class="tableArea" >
    <el-table
    class="tables"
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      type="index"
      label="序号"
      align="center"
      width="50">
    </el-table-column>
    <el-table-column
      prop="name"
      align="center"
      label="头像"
      >
      <template slot-scope="{row}">
        <el-image v-show="row.logo" style="height:60px;width:60px; object-fit: cover;" :src="row.logo" :preview-src-list="row.logo.split(',')"></el-image>
      </template>
    </el-table-column>
    <el-table-column
    align="center"
    prop="memberName"
      label="用户昵称">
    </el-table-column>
    <el-table-column
    align="center"
    prop="loginName"
      label="账号">
    </el-table-column>
    <el-table-column
    align="center"
    prop="createDate"
      label="注册时间">
    </el-table-column>
    <el-table-column
    align="center"
    prop="idCardNo"
      label="身份证号">
    </el-table-column>
    <el-table-column
    align="center"
      label="状态">
      <template slot-scope="{row}">
        <el-tag type="success" v-if="row.accountStatus==1">正常</el-tag>
        <el-tag type="info" v-if="row.accountStatus==2">注销</el-tag>
        <el-tag type="danger" v-if="row.accountStatus==3">拉黑</el-tag>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.currPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
</div>
    </div>
</template>

<script>
import { row } from '../../../components/custom/items/row'

    export default {
      props:{
            current:{
                type:String,
                default:"BaseInfoList"
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        data(){
            return{
              total:0,
                tableData:[],
                queryInfo:{
                    currPage:1,
                    pageSize:10,
                    condition:{
                        memberCode:''
                    }
                }
            }
        },
        created(){
            this.queryInfo.condition.memberCode=this.memberCode
            this.getTable()
        },
        methods:{
          async getTable(){
                let res=await this.$http.post('member/listByFollow',this.queryInfo)
                if(res.data.code==200){
                    this.total=res.data.data.count
                    this.tableData=res.data.data.data
                }else{
                    this.$message.error(res.data.message)
                }
            },
            handleSizeChange(v){
                this.queryInfo.pageSize=v
                this.getTable()
            },
            handleCurrentChange(v){
                this.queryInfo.currPage=v
                this.getTable()
            }
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
.tableArea{
    padding-right: 30px;
    box-sizing: border-box;
}
.tables{
    th{
        padding: 5px 0;
       
    }
}
</style>