<template>
    <div>
        <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText" >商品</span>
    </div> 
<div class="tableArea" >
    <ul class="category">
        <li :class="{'active':categoryCode==''}">全部</li>
        <li :class="{'active':categoryCode==item.categoryCode}" v-for="item,index in categoryList" :key="index">{{ item.categoryName }}</li>
    </ul>
<div class="tableA">
<div style="width:100%">
    <el-table
    class="tables"
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      type="index"
      label="序号"
      align="center"
      width="50">
    </el-table-column>
    <el-table-column
      prop="productName"
      align="center"
      label="商品名称"
      >
    </el-table-column>
    <el-table-column
    align="center"
    prop="bannerUr1"
      label="商品轮播图">
    </el-table-column>
    <el-table-column
    align="center"
    prop="productLogo"
      label="商品图片">
    </el-table-column>
    <el-table-column
    align="center"
    prop="categoryName"
      label="商品分类">
    </el-table-column>
    <el-table-column
    align="center"
      label="规格">
    </el-table-column>
    <el-table-column
    align="center"
    prop="price"
      label="商品价格">
    </el-table-column>
    <el-table-column
    align="center"
    prop="unit"
      label="单位">
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.currPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
</div>
</div>
</div>
    </div>
</template>

<script>
    export default {
        props:{
            current:{
                type:String,
                default:"BaseInfoList"
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        data(){
            return{
                categoryCode:"",
                categoryList:[],
                total:0,
                tableData:[],
                queryInfo:{
                    currPage:1,
                    pageSize:10,
                    condition:{
                        memberCode:''
                    }
                },
            }
        },
        methods:{
          async  getCategory(){
            let res=await this.$http.post('productCategory/queryListByMember',{categoryCode:this.categoryCode})
            if(res.data.code==200){
                this.categoryList=res.data.data
            }
            },
            async getTable(){
                let res=await this.$http.post('merchantProduct/listByHomepage',this.queryInfo)
                if(res.data.code==200){
                    this.total1=res.data.data.count
                    this.tableData1=res.data.data.data
                }else{
                    this.$message.error(res.data.message)
                }
            },
            handleSizeChange(v){
                this.queryInfo1.pageSize=v
                this.getTable()
            },
            handleCurrentChange(v){
                this.queryInfo1.currPage=v
                this.getTable()
            },
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
.tableArea{
    display: flex;
    padding-right: 30px;
    box-sizing: border-box;
    .category{
        width: 130px;
        padding-right: 12px;
        li{
            width: 100%;
            height: 38px;
            text-align: center;
            line-height: 38px;
            cursor: pointer;
        }
        li:hover{
            background-color: lighten(#ADE2FA, 13%);
        }
        .active{
            background-color: #ADE2FA;
        }
    }
    .tableA{
        display: flex;
        flex: 1;
    }
}
.tables{
    th{
        padding: 5px 0;
    }
}
</style>