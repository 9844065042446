<template>
    <div>
        <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText" >获赞量</span>
    </div> 
<div class="tableArea" >
    <el-table
    class="tables"
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      type="index"
      label="序号"
      align="center"
      width="50">
    </el-table-column>
    <el-table-column
      prop="name"
      align="center"
      label="获赞内容"
      >
    </el-table-column>
    <el-table-column
    align="center"
      label="点赞用户头像">
    </el-table-column>
    <el-table-column
    align="center"
      label="点赞用户昵称">
    </el-table-column>
    <el-table-column
    align="center"
      label="获赞总数">
    </el-table-column>
    <el-table-column
    align="center"
      label="点赞时间">
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.currPage"
      :page-sizes="[8, 24, 48, 96]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
</div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                total:0,
                tableData:[],
                queryInfo:{
                    currPage:1,
                    pageSize:8,
                }
            }
        },
        methods:{
            handleSizeChange(newSize){

            },
            handleCurrentChange(current){

            }
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
.tableArea{
    padding-right: 30px;
    box-sizing: border-box;
}
.tables{
    th{
        padding: 5px 0;
    }
}
</style>