<template>
    <div>
        <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText">点赞视频</span>
    </div>
    <ul class="list">
        <li v-for="item,index in tableData1" :key="index">
            <div class="left">
                <img :src="item.videoImg" alt="">
            </div>
            <div class="right">
                <div class="textItem">
                   <label>视频作者</label>
                   <span>{{ item.memberName }}</span>
                </div>
                <div class="textItem">
                   <label>视频文案</label>
                   <div>{{ item.title }}</div>
                </div>
                <div class="textItem">
                   <label>发布时间</label>
                   <span>{{ item.createDt }}</span>
                </div>
            </div>
        </li>
    </ul>
    <el-pagination
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      :current-page="queryInfo1.currPage"
      :page-sizes="[9, 36, 90, 180]"
      :page-size="queryInfo1.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total1">
    </el-pagination>
    <div class="ziliao" style="margin-top:12px">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText">点赞市集</span>
    </div>
    <ul class="shiji">
        <li v-for="item,index in tableData2" :key="index">
            <div class="item">
            <label>市集作者</label>
            <div class="value">{{ item.memberName }}</div>
            </div>
            <div class="item"><label>市集文案</label>
            <div class="value">{{ item.title }}</div></div>
            <div class="item"><label>图片/视频</label>
            <div class="imgList">
             <img style="margin-right: 6px;margin-bottom:6px" v-for="src,i in item.imgUrl.split(',')" :key="i" :src="src" alt="">
                </div></div>
            <div class="item"><label>发布时间</label>
            <div class="value">{{ item.createDt }}</div></div>
        </li>
    </ul>
    <el-pagination
      @size-change="handleSizeChange2"
      @current-change="handleCurrentChange2"
      :current-page="queryInfo2.currPage"
      :page-sizes="[8, 36, 100, 200]"
      :page-size="queryInfo2.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total2">
    </el-pagination>
    </div>
</template>

<script>
    export default {
        props:{
            current:{
                type:String,
                default:"BaseInfoList"
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        data(){
            return{
                total1:0,
                tableData1:[],
                queryInfo1:{
                    currPage:1,
                    pageSize:9,
                    condition:{
                        memberCode:''
                    }
                },
                total2:0,
                tableData2:[],
                queryInfo2:{
                    currPage:1,
                    pageSize:8,
                    condition:{
                        memberCode:''
                    }
                }
                
            }
        },
        created(){
            this.queryInfo1.condition.memberCode=this.memberCode
            this.queryInfo2.condition.memberCode=this.memberCode
            this.getTable1()
            this.getTable2()
            
        },
        methods:{
            async getTable1(){
                let res=await this.$http.post('video/listByLike',this.queryInfo1)
                if(res.data.code==200){
                    this.total1=res.data.data.count
                    this.tableData1=res.data.data.data
                }
            },
            async getTable2(){
                let res=await this.$http.post('fair/listByLike',this.queryInfo2)
                if(res.data.code==200){
                    this.total2=res.data.data.count
                    this.tableData2=res.data.data.data
                }
            },
            handleSizeChange1(v){
                this.queryInfo1.pageSize=v
                this.getTable1()
            },
            handleCurrentChange1(v){
                this.queryInfo1.currPage=v
                this.getTable1()
            },
        
            handleSizeChange2(v){
                this.queryInfo2.pageSize=v
                this.getTable2()
            },
            handleCurrentChange2(v){
                this.queryInfo2.currPage=v
                this.getTable2()
            }
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
.list{
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    li{
        width: calc((100% - 40px) / 3);
        padding: 12px;
        margin-right: 20px;
        box-sizing: border-box;
        border: 1px solid #f2f2f2;
        border-radius: 10px;
        display: flex;
        margin-bottom: 20px;
        &:nth-child(3n){
            margin-right: 0;
        }
        .left{
            width: 100px;
            height: 100px;
            overflow: hidden;
            &:hover img{
                scale: 1.5;
            }
            img{
                transition: 0.9s;
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
        }
        .right{
            padding-left: 12px;
            .textItem{
                margin-bottom: 6px;
                display: flex;
                label{
                    color: #555;
                }
                label::after{
                    content: ':';
                    padding-left: 2px;
                }
                div,span{
                    padding-left: 7px;
                    color: #000;
                }
                div{
                    width: 140px;
                    overflow : hidden;
                    word-break: break-all;
display: -webkit-box;
-webkit-line-clamp:3;
-webkit-box-orient: vertical;
                }
            }
        }
    }
}
.shiji{
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    li{
        width: calc((100% - 60px) / 4);
        margin-right: 15px;
        padding: 12px;
        margin-bottom: 12px;
        box-sizing: border-box;
        border: 1px solid #f2f2f2;
        border-radius: 10px;
        &:nth-child(4n){
            margin-right: 0;
        }
        .item{
            display: flex;
            margin-bottom: 6px;
            .imgList{
                width: 150px;
                min-height: 60px;
                padding-left: 7px;
                overflow: auto;
                img{
                height: 60px;
                width: 60px;
                object-fit: cover;
            }
            }
           
            label{
                color: #555;
            }
            label::after{
                    content: ':';
                    padding-left: 2px;
                }
            div.value{
                width: 150px;
                overflow : hidden;
                word-break: break-all;
display: -webkit-box;
-webkit-line-clamp:2;
-webkit-box-orient: vertical;
                padding-left: 7px;
            }
        }
    }
}
</style>