<template>
    <div>
        <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText">用户视频</span>
    </div>
    <ul class="list">
        <li v-for="item,index in tableData1" :key="index">
            <div class="left" @click="showVideo(item.videoUrl,item.rate)">
                <img :src="item.videoImg" alt="">
            </div>
            <div class="right">
                <div class="textItem">
                   <label>视频作者</label>
                   <span>{{item.memberName}}</span>
                </div>
                <div class="textItem">
                   <label>视频文案</label>
                   <div>{{item.title}}</div>
                </div>
                <div class="textItem">
                   <label>发布时间</label>
                   <span>{{item.createDt}}</span>
                </div>
            </div>
        </li>
    </ul>
    <el-pagination
      @size-change="handleSizeChange1"
      @current-change="handleCurrentChange1"
      :current-page="queryInfo1.currPage"
      :page-sizes="[9, 36, 90, 180]"
      :page-size="queryInfo1.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total1">
    </el-pagination>
    <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText">用户市集</span>
    </div>
    <ul class="shiji">
        <li v-for="item,index in tableData2" :key="index">
            <div class="item">
            <label>市集作者</label>
            <div class="value">{{item.memberName}}</div>
            </div>
            <div class="item"><label>市集文案</label>
            <div class="value">{{item.title}}</div></div>
            <div class="item"><label>图片/视频</label>
            <div class="imgList">
            <el-image  :preview-src-list="item.imgUrl.split(',')"
            v-show="item.imgUrl" style="height: 60px; width: 60px;object-fit: cover;margin-right:6px;margin-bottom:6px" v-for="img,i in item.imgUrl.split(',')" :src="img" :key="i"></el-image>
                </div></div>
            <div class="item"><label>发布时间</label>
            <div class="value">{{item.createDt}}</div></div>
            <div class="item"><label>获赞数量</label>
            <div class="value">15000</div></div>
        </li>
    </ul>
    <el-pagination
      @size-change="handleSizeChange2"
      @current-change="handleCurrentChange2"
      :current-page="queryInfo2.currPage"
      :page-sizes="[8, 36, 80, 200]"
      :page-size="queryInfo2.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total2">
    </el-pagination>
    <el-dialog
     v-dialogDrag
    append-to-body
  title="视频"
  :visible.sync="dialogVisible"
  width="fit-content"
  >
   <video controls autoplay muted :style="[{'height':'80vh'},{'aspect-ratio':rate}]"  :src="videoUrl"></video>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
  </span>
</el-dialog>
    </div>
</template>

<script>
    export default {
        props:{
            current:{
                type:String,
                default:"BaseInfoList"
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        data(){
            return{
                rate:1,
                videoUrl:'',
                dialogVisible:false,
                total1:0,
                tableData1:[],
                queryInfo1:{
                    currPage:1,
                    pageSize:9,
                    condition:{
                        memberCode:''
                    }
                },
                total2:0,
                tableData2:[],
                queryInfo2:{
                    currPage:1,
                    pageSize:8,
                    condition:{
                        memberCode:''
                    }
                }
            }
        },
        created(){
            this.queryInfo1.condition.memberCode=this.memberCode
            this.queryInfo2.condition.memberCode=this.memberCode
            this.getTable1()
            this.getTable2()
            
        },
        methods:{
            showVideo(url,rate){
                this.videoUrl=url
                this.rate=rate
                this.dialogVisible=true
            },
            async getTable1(){
                let res=await this.$http.post('video/pcListByHomepage',this.queryInfo1)
                if(res.data.code==200){
                    this.total1=res.data.data.count
                    this.tableData1=res.data.data.data
                }
            },
            async getTable2(){
                let res=await this.$http.post('fair/pcListByHomepage',this.queryInfo2)
                if(res.data.code==200){
                    this.total2=res.data.data.count
                    this.tableData2=res.data.data.data
                }
            },
            handleSizeChange1(v){
                this.queryInfo1.pageSize=v
                this.getTable1()
            },
            handleCurrentChange1(v){
                this.queryInfo1.currPage=v
                this.getTable1()
            },
        
            handleSizeChange2(v){
                this.queryInfo2.pageSize=v
                this.getTable2()
            },
            handleCurrentChange2(v){
                this.queryInfo2.currPage=v
                this.getTable2()
            }
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
.list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
        width: calc((100% - 40px) / 3);
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #f2f2f2;
        border-radius: 10px;
        display: flex;
        margin-bottom: 20px;
        .left{
            width: 100px;
            height: 100px;
            overflow: hidden;
        //    &:hover img{
        //        scale: 1.5;
       //     }
            img{
                cursor: pointer;
                transition: 0.9s;
                width: 100px;
                height: 100px;
                object-fit: cover;
            }
        }
        .right{
            padding-left: 12px;
            .textItem{
                margin-bottom: 6px;
                display: flex;
                label{
                    color: #555;
                }
                label::after{
                    content: ':';
                    padding-left: 2px;
                }
                div,span{
                    padding-left: 7px;
                    color: #000;
                }
                div{
                    width: 140px;
                    overflow : hidden;
                    word-break: break-all;
display: -webkit-box;
-webkit-line-clamp:3;
-webkit-box-orient: vertical;
                }
            }
        }
    }
}
.shiji{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
        width: calc((100% - 60px) / 4);
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #f2f2f2;
        border-radius: 10px;
        .item{
            display: flex;
            margin-bottom: 6px;
            .imgList{
                width: 150px;
                min-height: 60px;
                padding-left: 7px;
                overflow: auto;
                img{
                height: 60px;
                width: 60px;
                object-fit: cover;
            }
            }
           
            label{
                color: #555;
            }
            label::after{
                    content: ':';
                    padding-left: 2px;
                }
            div.value{
                
                width: 150px;
                overflow : hidden;
                word-break: break-all;
display: -webkit-box;
-webkit-line-clamp:2;
-webkit-box-orient: vertical;
                padding-left: 7px;
            }
        }
    }
}
</style>