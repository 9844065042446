import { render, staticRenderFns } from "./UserPosts.vue?vue&type=template&id=6b84cf5d&scoped=true"
import script from "./UserPosts.vue?vue&type=script&lang=js"
export * from "./UserPosts.vue?vue&type=script&lang=js"
import style0 from "./UserPosts.vue?vue&type=style&index=0&id=6b84cf5d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b84cf5d",
  null
  
)

component.options.__file = "UserPosts.vue"
export default component.exports