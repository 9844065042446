<template>
    <div>
        <el-dialog
       top="80px"
       v-dialogDrag
  :visible.sync="detailDialog"
  width="1160px"
  :before-close="handleClose">
  <template slot="title">
    用户详情
  </template>
  <div class="headTabs" v-loading="baseInfoLoading">
    <div class="baseInfo">
      <div class="img"><img :src="baseInfo.member.logo" alt=""></div>
      <div class="textInfo">
        <div class="top">
          <div class="name" style="width:54px">{{ baseInfo.member.memberName }}</div>
          <div class="phone">{{ baseInfo.member.phone }}</div>
          <div class="tag1"><img src="../../../assets/images/userInfo1.png" alt=""><span>企业认证</span></div>
          <div class="tag2"><img src="../../../assets/images/userInfo2.png" alt=""><span>销量排名前10</span></div>
          <div class="tag3"> <img src="../../../assets/images/userInfo3.png" alt=""><span>个人认证</span></div>
        </div>
        <div class="bottom">
          <span>注册时间：</span>
          <span>{{ baseInfo.member.createDate }}</span>
          <span class="textRight">注册天数：</span>
          <span>{{baseInfo.createDays}}</span>
        </div>
      </div>
      <div class="status">
        <span class="normal statusText" v-if="baseInfo.member.accountStatus==1">正常</span>
        <span class="normal statusText zhuxiao" v-if="baseInfo.member.accountStatus==2">注销</span>
        <span class="normal statusText lahei" v-if="baseInfo.member.accountStatus==3">拉黑</span>
        <span class="text">用户状态</span>
      </div>
      <div class="num1" v-for="item,index in baseInfoList" :key="index">
        <div class="top" :style="[{color:item.color}]">{{ item.num }}</div>
        <div class="bottom"> {{ item.title }}数量</div>
      </div>
    </div>
   <div class="mainContent">
    <el-tabs v-model="current"  >
  <el-tab-pane
    v-for="(item, index) in tabs"
    :key="index"
    :label="item.label"
    :name="item.name"
  >
  
  </el-tab-pane>
</el-tabs>
<component :is="current" :id="id" :current="current" :memberCode="memberCode"></component>
   </div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleClose">确 定</el-button>
  </span>
</el-dialog>

    </div>
</template>

<script>
import drag from '../../../components/drag'
import BaseInfoList from './BaseInfoList.vue'
import UserBehavioral from './UserBehavioral.vue'
import LikeContent from './LikeContent.vue'
import SendContent from './SendContent.vue'
import CircleInfo from './CircleInfo.vue'
import FansList from './FansList.vue'
import FocusList from './FocusList.vue'
import UserPosts from './UserPosts.vue'
import PraisedContent from './PraisedContent.vue'
import OrderList from './OrderList.vue'
import ProductList from './ProductList.vue'
    export default {
        components:{ BaseInfoList,UserBehavioral,LikeContent,SendContent,CircleInfo,FansList,FocusList,
          UserPosts,PraisedContent,OrderList,ProductList
        },
        watch:{
          detailDialog(v){
            if(v){
              this.getBaseInfo()
            }
          }
        },
        props:{
            detailDialog:{
                type:Boolean,
                default:()=>flase
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        data(){
            return{
              current:'BaseInfoList',
              baseInfoLoading:false,
              baseInfo:{
                createDays:"",
                member:{
                  accountStatus:"1",
                  logo:"",
                  memberName:"",
                  phone:"",
                  createDate:'',
                }
              },
              baseInfoList:[
                {title:'视频',color:'02a7f0',num:0},
                {title:'市集',color:'ec808d',num:0},
                {title:'圈子',color:'#f59a23',num:0},
                {title:'订单',color:'#0000ff',num:0},
                {title:'关注',color:'#a36717',num:0},
                {title:'粉丝',color:'#8400ff',num:0},
              ],
                tabs:[
                    {name:"BaseInfoList",label:'基本信息'},
                    {name:"UserBehavioral",label:'用户行为轨迹'},
                    {name:"LikeContent",label:'点赞内容'},
                    {name:"SendContent",label:'转发内容'},
                    {name:"CircleInfo",label:'圈子'},
                    {name:"FansList",label:'粉丝'},
                    {name:"FocusList",label:'关注'},
                    {name:"UserPosts",label:'用户发布'},
                    {name:"PraisedContent",label:'获赞量'},
                    {name:"OrderList",label:'订单'},
                    // {name:"11",label:'点赞市集'},
                    {name:"ProductList",label:'商品'}
                ]
            }
        },
        methods:{
       async   getBaseInfo(){
        this.baseInfoLoading=true
        let res=await this.$http.post('member/queryOnePc',{id:this.id})
        this.baseInfoLoading=false
        if(res.data.code==200){
          this.baseInfo=res.data.data
          let {videoNum,fairNum,circleNum,orderNum,followNum,followNumOther}=res.data.data
          this.baseInfoList[0].num=videoNum 
          this.baseInfoList[1].num=fairNum
          this.baseInfoList[2].num=circleNum
          this.baseInfoList[3].num=orderNum
          this.baseInfoList[4].num=followNum
          this.baseInfoList[5].num=followNumOther

        }else{
          this.$message.error(res.data.message)
        }
          },
            handleClose(){
                this.$emit('handleClose',false)
            }
        }
    }
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__wrapper{
  overflow: visible;
}
/deep/  .el-dialog__header{
    border-bottom: 1px solid #ccc;
    padding: 20px 20px 10px;
}
/deep/ .el-dialog__body{
  padding: 16px 20px;
  height: 80vh;
  box-sizing: border-box;
  overflow: scroll;
  background: #F2F2F2;
  .baseInfo{
    display: flex;
    padding: 16px;
    background-color: #fff;
    border-radius: 12px;

    .img{
      img{
        height: 56px;
        width: 56px;
        border-radius: 50%;
      }
      padding-right: 12px;
    }
    .textInfo{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 58px;
      box-sizing: border-box;
      padding: 6px 0;
      .top{
        display: flex;
        .name{
          font-size: 15px;
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .phone{
          padding-left: 0;
          padding-right: 12px;
        }
      .tag2,.tag3{
          margin-left: 6px;
        }
        .tag1{
         color: #dbb28c;
         font-size: 10px;
         background-color: rgba(0, 0, 0, 1);
         border: 1px solid rgba(219, 178, 140, 1);
         display: flex;
         justify-content: space-between;
         align-items: center;
         border-radius: 3px;
         padding: 0 4px;
        }
        .tag2{
          display: flex;
         justify-content: space-between;
         align-items: center;
         border-radius: 3px;
         padding: 0 4px;
         color: #D9001B;
         border: 1px solid rgba(217, 0, 27, 1);
         font-size: 10px;
         background-color: rgba(0, 0, 0, 1);
        }
        .tag3{
          color: #02a7f0;
         font-size: 10px;
         background-color: #fff;
         border: 1px solid #02a7f0;
         display: flex;
         justify-content: space-between;
         align-items: center;
         border-radius: 3px;
         padding: 0 4px;
        }
        .tag1,.tag2,.tag3{
          span{
            padding-left: 2px;
          }
        }
        img{
          height: 12px;
          display: block;
          
        }
      }
      .bottom{
        display: flex;
        font-size: 13px;
        .textRight{
          padding-left: 24px;
        }
      }
    }
    .status{
         height: 58px;
         display: flex;
        flex-direction: column;
         justify-content: center;
         align-items: center;
         padding: 0 20px;
         .statusText{
          font-size: 15px;
          font-weight: 600;
          padding-left: 13px;
          position: relative;
         }
         .statusText::before{
          content: '';
          display: block;
          position: absolute;
          top: 8px;
          left: 0;
          height: 6px;
          width: 6px;
          background-color: #63a103;
          border-radius: 50%;
         }
         .normal{
          color: #63a103;
         }
         .zhuxiao{
          color: #ccc;
         }
         .zhuxiao::before{
          background-color: #ccc;
         }
         .lahei{
          color: red;
         }
         .lahei::before{
          background-color: red;
         }
         .text{
          font-size: 12px;
          color: #7f7f7f;
          margin-top: 4px;
         }
        }
      .num1{
        height: 58px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .top{
          font-size: 15px;
          font-weight: 600;
        }
        .bottom{
          font-size: 12px;
          color: #7f7f7f;
          margin-top: 4px;
        }
      }
  }
  .headTabs{
    height: fit-content;
    user-select: none;
    .el-tabs__nav-wrap::after{
      background-color: #fff;
    }
    .el-tabs__item{
        background: #fff;
        color: #555;
        border-color: transparent;
    }
    .is-active{
        // color: #555;
        color: #409EFF;
        font-weight: 600;
    }
    .el-tabs__active-bar{
      height: 4px;
    }
  }
  .mainContent{
    background-color: #fff;
    padding: 6px 16px;
    margin-top: 16px;
  }
}
</style>