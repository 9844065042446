import { render, staticRenderFns } from "./SendContent.vue?vue&type=template&id=44a03d57&scoped=true"
import script from "./SendContent.vue?vue&type=script&lang=js"
export * from "./SendContent.vue?vue&type=script&lang=js"
import style0 from "./SendContent.vue?vue&type=style&index=0&id=44a03d57&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44a03d57",
  null
  
)

component.options.__file = "SendContent.vue"
export default component.exports