<template>
    <div>
    <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText" >用户资料</span>
    </div>
    <div class="baseInfos">
        <div class="left" >
           <div class="leftItem" >
            <div class="key">用户名称</div>
            <span class="value">{{ baseObj.memberName }}</span>
           </div>
           <div class="leftItem" >
            <div class="key">登录账号</div>
            <span class="value">{{ baseObj.loginName }}</span>
           </div>
           <div class="leftItem" >
            <div class="key">手机号</div>
            <span class="value">{{ baseObj.phone }}</span>
           </div>
           <div class="leftItem" >
            <div class="key">性别</div>
            <span v-show="baseObj.sex" class="value">{{ baseObj.sex }}</span>
            <span v-show="!baseObj.sex" class="value">--</span>
           </div>
           <div class="leftItem" >
            <div class="key">地区</div>
            <span class="value" v-show="baseObj.provinceName">{{ baseObj.provinceName}}{{ baseObj.cityName }}</span>
            <span class="value" v-show="!baseObj.provinceName">--</span>
           </div>
           <div class="leftItem" >
            <div class="key">生日</div>
            <span class="value" v-show="baseObj.birthday">{{ baseObj.birthday }}</span>
            <span class="value" v-show="!baseObj.birthday">--</span>
           </div>
           <div class="leftItem" >
            <div class="key">注册渠道</div>
            <span class="value" v-show="baseObj.registerSource">{{ baseObj.registerSource }}</span>
            <span class="value" v-show="!baseObj.registerSource">--</span>
           </div>
           <div class="leftItem" >
            <div class="key">注册时间</div>
            <span class="value">{{ baseObj.createDate }}</span>
           </div>
           <div class="leftItem" >
            <div class="key">拉新人数</div>
            <span class="value">{{baseObj.lowerNum}}人</span>
           </div>
        </div>
        <div class="right">
            <div class="top">
                <span>身份证正面</span>
                <img style="height:46px" :src="baseObj.idCardFront" alt="">
            </div>
            <div class="bottom">
                <span>身份证反面</span>
                <img style="height:46px" :src="baseObj.idCardBack" alt="">
            </div>
        
        </div>
    </div>
    <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText" >用户标签</span>
    </div>
    <div class="labels">
        <el-tag v-for="tag,index in baseObj.labelList" :key="index" style="margin-right:8px">{{ tag.label }}</el-tag>
    </div>
    <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText">收货地址</span>
    </div>
    <div class="border">
        <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
    align="center"
      prop="consignee"
      label="姓名"
      >
    </el-table-column>
    <el-table-column
    align="center"
      prop="phone"
      label="手机号"
     >
    </el-table-column>
    <el-table-column
    align="center"
      prop="provinceName"
      label="省级">
    </el-table-column>
    <el-table-column
    align="center"
      prop="cityName"
      label="市级">
    </el-table-column>
    <el-table-column
    align="center"
      prop="areaName"
      label="区级">
    </el-table-column>
    <el-table-column
    align="center"
      prop="detail"
      label="详细地址">
    </el-table-column>
    <el-table-column
    align="center"
      label="默认地址">
      <template slot-scope="{row}">
        <el-switch
        disabled
  v-model="row.is_default"
  active-value="1"
    inactive-value="0"
  active-color="#13ce66"
  inactive-color="#ff4949">
</el-switch>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.currPage"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
    </div>
</template>

<script>
    export default {
        props:{
            current:{
                type:String,
                default:"BaseInfoList"
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        created(){
            this.queryInfo.condition.memberCode=this.memberCode
            this.getBase()
            this.getBaseInfoTable()
            
        },
        data(){
            return{
                baseObj:{},
                total:0,
                tableData:[],
                queryInfo:{
                    currPage:1,
                    pageSize:10,
                    condition:{
                        memberCode:''
                    }
                }
            }
        },
        methods:{
            async getBase(){
                let res=await this.$http.post('member/basicInformation',{id:this.id})
                if(res.data.code==200){
                    this.baseObj=res.data.data
                }else{
                    this.$message.error(res.data.message)
                }
            },
            async getBaseInfoTable(){
                let res=await this.$http.post('memberAddress/listByMember',this.queryInfo)
                if(res.data.code==200){
                    this.total=res.data.data.count
                    this.tableData=res.data.data.data
                }
            },
            handleSizeChange(v){
                this.queryInfo.pageSize=v
                this.getBaseInfoTable()
            },
            handleCurrentChange(v){
                this.queryInfo.currPage=v
                this.getBaseInfoTable()
            }
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
.baseInfos{
        display: flex;
        // margin-top: 12px;
        padding: 12px 0 12px 60px;
        
        .left{
            width: 660px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .leftItem{
                width: calc(100% / 3);
                padding: 10px 0;
                display: flex;
                .key{
                    min-width: 68px;
                    text-align: right;
                }
                .value{
                    padding-left: 6px;
                }
                .key::after{
                    content: ':';
                    padding-left: 3px;
                }
            }
        }
        .right{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            >div{
                display: flex;
                align-items: top;
                span{
                    padding-right: 6px;
                }
            }
            >div span::after{
                content:":";
                padding-left: 3px;
            }

        }
    }
    .labels{
        padding: 14px 0 14px 29px;
        display: flex;
        flex-wrap: wrap;
    }
    .border{
        box-sizing: border-box;
        padding-left: 46px;
    }
</style>