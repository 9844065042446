import { render, staticRenderFns } from "./LikeContent.vue?vue&type=template&id=365c40ab&scoped=true"
import script from "./LikeContent.vue?vue&type=script&lang=js"
export * from "./LikeContent.vue?vue&type=script&lang=js"
import style0 from "./LikeContent.vue?vue&type=style&index=0&id=365c40ab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "365c40ab",
  null
  
)

component.options.__file = "LikeContent.vue"
export default component.exports