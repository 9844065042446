<template>
    <div>
        <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText">用户行为轨迹</span>
    </div>
    <div class="tableDiv">
        <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
    align="center"
      type="index"
      label="序号"
      >
    </el-table-column>
    <el-table-column
    align="center"
      prop="name"
      label="头像"
     >
    </el-table-column>
    <el-table-column
    align="center"
      prop="address"
      label="昵称">
    </el-table-column>
    <el-table-column
    align="center"
      prop="address"
      label="页面">
    </el-table-column>
    <el-table-column
    align="center"
      prop="address"
      label="页面内容">
    </el-table-column>
    <el-table-column
    align="center"
      prop="address"
      label="第一次访问时间">
    </el-table-column>
    <el-table-column
    align="center"
      label="最后一次访问时间">
    </el-table-column>
    <el-table-column
    align="center"
      label="访问时长">
    </el-table-column>
  </el-table>
  <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.currPage"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
    </div>
</template>

<script>
    export default {
      props:{
            current:{
                type:String,
                default:"BaseInfoList"
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        data(){
            return{
              total:0,
                tableData:[],
                queryInfo:{
                    currPage:1,
                    pageSize:10,
                    condition:{
                        memberCode:''
                    }
                }
            }
        },
        created(){
          this.queryInfo.condition.memberCode=this.memberCode
          this.getBaseTable()
        },
        methods:{
          async getBaseTable(){
                let res=await this.$http.post('memberViewTime/listByMember',this.queryInfo)
                if(res.data.code==200){
                    this.total=res.data.data.count
                    this.tableData=res.data.data.data
                }
            },
            handleSizeChange(v){
                this.queryInfo.pageSize=v
                this.getBaseTable()
            },
            handleCurrentChange(v){
                this.queryInfo.currPage=v
                this.getBaseTable()
            }
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
.tableDiv{
   padding-left: 46px;
}
</style>