<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.condition.queryKey"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        v-loading="tableLoading"
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        height="calc(100vh - 220px)"
      >
        <el-table-column
          label="序号"
          fixed="left"
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          label="用户名称"
          fixed="left"
          align="center"
          min-width="110px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.memberName }}</template>
        </el-table-column>
        <el-table-column
          min-width="110px"
          fixed="left"
          label="登录账号"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.loginName }}</template>
        </el-table-column>

        <el-table-column label="手机号" min-width="110" align="center">
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="头像" min-width="90" align="center">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :preview-src-list="[scope.row.logo]"
              :src="scope.row.logo"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="账号状态" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.accountStatus == 1">正常</el-tag>
            <el-tag type="info" v-if="scope.row.accountStatus == 2"
              >注销</el-tag
            >
            <el-tag type="danger" v-if="scope.row.accountStatus == 3"
              >拉黑</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="是否实名认证"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag v-show="scope.row.isAuth == 1" type="success"
              >已认证</el-tag
            >
            <el-tag v-show="scope.row.isAuth == 0" type="warning"
              >未认证</el-tag
            >
          </template>
        </el-table-column>
        <!-- <el-table-column label="性别" align="center" >
            <template slot-scope="scope">{{ scope.row.sex }}</template>
          </el-table-column>

          <el-table-column label="出生年月" align="center" >
            <template slot-scope="scope">{{ scope.row.birthday }}</template>
          </el-table-column> -->
        <el-table-column label="地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.provinceName }}-{{ scope.row.cityName }}-{{ scope.row.areaName }}</template>
          </el-table-column>

        <!-- <el-table-column label="省级名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.provinceName }}</template>
        </el-table-column>

        <el-table-column label="市级名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.cityName }}</template>
        </el-table-column>

        <el-table-column label="区级名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.areaName }}</template>
        </el-table-column> -->
        <el-table-column label="真实姓名" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.trueName }}</template>
        </el-table-column>
        <!-- <el-table-column label="年龄" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.age }}</template>
          </el-table-column>
          <el-table-column label="经度" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.longitude }}</template>
          </el-table-column>
          <el-table-column label="纬度" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.latitude }}</template>
          </el-table-column> -->

        <el-table-column
          label="身份证号码"
          min-width="180px"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.idCardNo }}</template>
        </el-table-column>

        <el-table-column label="人脸图片" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              v-if="scope.row.faceUrl"
              :src="scope.row.faceUrl"
            ></el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column label="账号状态" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.accountStatus }}</template>
          </el-table-column> -->
        <el-table-column
          label="身份证正面图片"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.idCardFront"
              style="width: 60px; height: 60px"
              :src="scope.row.idCardFront"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="身份证反面图片"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.idCardBack"
              style="width: 60px; height: 60px"
              :src="scope.row.idCardBack"
            ></el-image
          ></template>
        </el-table-column>
        <el-table-column
          min-width="150px"
          label="创建时间"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.createDate }}</template>
        </el-table-column>
        <el-table-column
          label="修改时间"
          min-width="150px"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.updateDate }}</template>
        </el-table-column>
        <el-table-column label="背景图" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.bgUrl"
              style="width: 60px; height: 60px"
              :src="scope.row.bgUrl"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="简介" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.memberInfo }}</template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          class-name="btns"
          width="165"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              style="color: #1a5d64"
              type="text"
              @click="showDetail(scope.row)"
            >
              详情
            </el-button>
            <el-button
              style="margin-left: 0"
              type="text"
              @click="addLabel(scope.row)"
            >
              标签
            </el-button>
            <el-popconfirm
              v-if="scope.row.accountStatus == 1"
              title="确定拉黑吗？"
              @confirm="block(true, scope.row.id)"
            >
              <el-button style="color: #f5b323" slot="reference" type="text"
                >拉黑</el-button
              >
            </el-popconfirm>
            <el-popconfirm
              v-else
              title="解禁该用户？"
              @confirm="block(false, scope.row.id)"
            >
              <el-button style="color: green" slot="reference" type="text"
                >解禁</el-button
              >
            </el-popconfirm>
            <el-button
              type="text"
              style="color: red"
              @click="handleDelte(scope.row.id)"
            >
              移除
            </el-button>
            <!-- <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="labelType == 'add' ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="800px"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="登录账号：" required prop="loginName">
              <el-input
                v-model="form.loginName"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号：" required prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="密码：" required>
              <el-input
                show-password
                v-model="form.pwd"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名称：" required>
              <el-input
                v-model="form.memberName"
                placeholder="请输入用户名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="头像：" prop="logo">
              <el-upload
                class="avatar-uploader"
                :action="$uploadURL"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.logo" :src="form.logo" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip">请上传正方形头像，大小不要超过1m</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加标签 -->
    <el-dialog
      :title="'添加标签___' + labelObj.memberName"
      :visible.sync="labelDialog"
      width="620px"
    >
      <el-form label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户标签">
              <div>
                <el-input
                  @keyup.enter.native="addTagList"
                  style="width: 220px"
                  v-model="labelObj.label"
                ></el-input>
                <el-button @click="addTagList" type="text">添加</el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-tag
                @close="delTag(item.id, index)"
                style="margin-right: 6px"
                v-for="(item, index) in tagList"
                :key="index"
                closable
                type="success"
              >
                {{ item.label }}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="labelDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
    <detailDialog
      @handleClose="detailDialog = false"
      :id="labelObj.id"
      :memberCode="labelObj.memberCode"
      :detailDialog="detailDialog"
    />
  </div>
</template>

<script>
import detailDialog from "./components/detailDialog.vue";
export default {
  components: {
    detailDialog,
  },
  data() {
    var loginNameChecked = (rule, value, callback) => {
      if (!/^1[34578][0-9]{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    var phoneChecked = (rule, value, callback) => {
      if (!/^1[34578][0-9]{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      tableLoading: false,
      detailDialog: false,
      labelObj: {
        id: "",
        memberName: "",
        label: "",
        memberCode: "",
      },
      tagList: [],
      labelDialog: false,
      labelType: "add",
      rules: {
        loginName: [
          { validator: loginNameChecked, trigger: ["blur", "change"] },
        ],
        phone: [{ validator: phoneChecked, trigger: ["blur", "change"] }],
      },
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {},
      },
      form: {
        memberName: "",
        loginName: "",
        pwd: "",
        phone: "",
        logo: "",
      },
      showDialog: false,
      tableData: [],
    };
  },

  created() {
    this.getList();
  },

  mounted() {},

  methods: {
    showDetail(row) {
      this.labelObj.id = row.id;
      this.labelObj.memberCode = row.memberCode;
      this.detailDialog = true;
    },
    async delTag(id, index) {
      console.log(id, index);
      let res = await this.$http.post("memberLabel/delete", { id });
      if (res.data.code == 200) {
        this.getUserLabel(this.labelObj.memberCode);
      } else {
        this.$message.error(res.data.message);
      }
    },
    async addTagList() {
      let arr = [];
      this.tagList.forEach((item) => {
        arr.push(item.label);
      });
      if (this.labelObj.label.trim()) {
        let isRepeat = arr.includes(this.labelObj.label.trim());
        if (isRepeat) {
          this.$message.info("当前标签已存在，请勿重复添加");
        } else {
          let { label, memberCode } = this.labelObj;
          let res = await this.$http.post("memberLabel/add", {
            label,
            memberCode,
          });
          if (res.data.code == 200) {
            this.labelObj.label = "";
            this.getUserLabel(this.labelObj.memberCode);
          } else {
            this.$message.error(res.data.message);
          }
        }
      }
    },
    addLabel(row) {
      this.labelDialog = true;
      this.labelObj.memberCode = row.memberCode;
      this.labelObj.memberName = row.memberName;
      this.getUserLabel(row.memberCode);
    },
    async block(flag, id) {
      let accountStatus = 1;
      if (flag) {
        accountStatus = 3;
      } else {
        accountStatus = 1;
      }
      let res = await this.$http.post("member/updateMember", {
        id,
        accountStatus,
      });
      if (res.data.code == 200) {
        this.getList();
      } else {
        this.$message.error(res.data.message);
      }
    },

    //获取数据
    getList() {
      this.tableLoading = true;
      var that = this;
      that.$http.post("/member/list", that.queryInfo).then(function (response) {
        that.tableLoading = false;
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage;
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
    handleSave() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.form));
          if (this.labelType == "add") {
            let res = await this.$http.post("member/addMember", data);
            if (res.data.code == 200) {
              this.$notify.success({
                title: "提示",
                message: "保存成功",
                showClose: true,
              });
              this.showDialog = false;
              this.resetForm();
              this.getList();
            } else {
              this.$message.error(res.data.message);
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      // that.$http.post("/member/save", that.form)
    },

    //修改
    edit(row) {
      this.showDialog = true;
      this.labelType = "edit";
      this.form = JSON.parse(JSON.stringify(row));
    },

    // 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm(
        "移除用户将删除该用户所有信息，请谨慎操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http.post("member/delete", { id }).then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        });
      }
    },
    async getUserLabel(memberCode) {
      let res = await this.$http.post("memberLabel/queryList", { memberCode });
      if (res.data.code != 200) {
        this.$message.error(res.data.message);
      }
      this.tagList = res.data.data;
      console.log(this.tagList, "taglist");
      return res;
    },
    resetForm() {
      this.form.memberName = "";
      this.form.loginName = "";
      this.form.pwd = "";
      this.form.phone = "";
      this.form.logo = "";
    },
    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.resetForm();
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        this.form.logo = res.data.url;
      } else {
        this.$message.error(res.message);
      }
      // this.form.logo =res.data.url
    },
    beforeAvatarUpload(file) {
      const isImg = file.type.slice(0, 5) === "image";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isImg) {
        this.$message.error("请上传图片!");
      }
      if (!isLt1M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return isImg && isLt1M;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .btns {
  .el-button {
    padding: 0 2px;
  }
}
/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #409eff;
  cursor: pointer;
}
/deep/.el-table__fixed {
  height: calc(100% - 8px) !important;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/depp/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
/deep/.avatar {
  width: 78px;
  height: 78px;
  display: block;
}
</style>
