<template>
    <div>
        <div class="ziliao">
        <img class="imgLabel" src="../../../assets/images/info1.png" alt="">
        <span class="imgText" >圈子</span>
    </div> 
    <ul class="list">
        <li v-for="item,index in tableData" :key="index">
            <div class="item">
                <label >圈子头像</label>
                <div class="logo"><img :src="item.circleLogo" alt=""></div>
            </div>
            <div class="item">
                <label >圈子介绍</label>
                <div><img :src="item.circleUrl" alt=""></div>
            </div>
            <div class="item">
                <label >圈子类型</label>
                <div><span>光伏行业</span></div>
            </div>
            <div class="item">
                <label >圈子公告</label>
                <div class="notice"><span>文明聊天。文明聊天。文明聊天。</span></div>
            </div>
            <div class="item">
                <label >圈子二维码</label>
                <div><img src="https://jin88.top/hsxy/img/tx.png" alt=""></div>
            </div>
            <div class="item">
                <label >圈子成员</label>
                <div class="last">
                    <div class="tag">
                        <img src="https://jin88.top/hsxy/img/tx.png" alt="">
                        <span>张三</span>
                    </div>
                    <div class="tag">
                        <img src="https://jin88.top/hsxy/img/tx.png" alt="">
                        <span>张三</span>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.currPage"
      :page-sizes="[8, 24, 48, 96]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
</template>

<script>
    export default {
        props:{
            current:{
                type:String,
                default:"BaseInfoList"
            },
            id:{
                type:String | Number,
                default:""
            },
            memberCode:{
              type:String | Number,
                default:""
            }
        },
        data(){
            return{
                total:0,
                tableData:[],
                queryInfo:{
                    currPage:1,
                    pageSize:8,
                    condition:{
                        memberCode:''
                    }
                }
            }
        },
        created(){
            this.queryInfo.condition.memberCode=this.memberCode
            this.getTable()
            
        },
        methods:{
            async getTable(){
                let res=await this.$http.post('circle/listByMember',this.queryInfo)
                if(res.data.code==200){
                    this.total=res.data.data.count
                    this.tableData=res.data.data.data
                }else{
                    this.$message.error(res.data.message)
                }
            },
            handleSizeChange(v){
                this.queryInfo.pageSize=v
                this.getTable()
            },
            handleCurrentChange(v){
                this.queryInfo.currPage=v
                this.getTable()
            }
        }
    }
</script>

<style lang="scss" scoped>
.ziliao{
    display: flex;
     align-items: center;
     padding-top: 6px;
     img.imgLabel{
        height: 20px;
    }
    .imgText{
        font-size: 15px;
        font-weight: 600;
        color: #555;
        margin-left: 8px;
        
    }

}
 ul.list{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-right: 40px;
    li{
        width: calc((100% - 80px) / 4);
        box-sizing: border-box;
        border: 1px solid rgba(215, 215, 215, 1);
        margin-right: 20px;
        border-radius: 10px;
        margin-bottom: 20px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        color: #000;
        font-size: 13px;
        .item{
            label{
                color: #555;
            }
            display: flex;
            align-items: top;
            margin-bottom: 10px;
            label::after{
                content: ':';
                padding: 0 8px 0 2px;
            }
            div.last{
                display: flex;
                flex-wrap: wrap;
            }
            >div{
                width: calc(100% - 80px);
              .tag{
                width: fit-content;
                border: 1px solid rgba(215, 215, 215, 1);
                padding: 2px 4px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                margin-right: 6px;
                margin-bottom: 6px;
                span{
                    padding-left: 4px;
                    color: #7f7f7f;
                    font-size: 13px;
                }
                img{
                    height: 18px;
                    width: 18px;
                    border-radius: 50%;
                }
              }
            }
            .logo{
                img{
                    border-radius: 4px;
                }
            }
        }
        img{
            height: 30px;
            width: 30px;
        }
    }
 }
</style>